function About() {
    let pStyle = 'py-1 text-justify'
    // let subheading = 'lg:text-2xl text-xl poppins-bold px-4';
    let content = 'pb-8'
    let tech = 'uppercase bg-[var(--secondary-color)] p-1 px-4 rounded-lg border-2 border-[var(--primary-color)]';
    const languages = ["Java", "JavaScript", "C/C++", "Python", "HTML/CSS", "R", "Swift", "Ruby", "Bash"];
    const frameworks = ["AWS", "React", "Node.js", "Rails", "React Native", "Swift UI", "Git", "Docker", "express.js",
        "Firebase"];
    const boldText = "poppins-bold";
    return (
        <div className='flex flex-wrap md:px-12 px-6 max-w-[1100px] mx-auto'>
            {/*EDUCATION/SKILLS/AWARDS*/}
            <div className='w-full md:w-1/2 p-6 '>
                {/*EDUCATION*/}
                <div>
                    {/*<div className={subheading}>*/}
                    {/*    Education*/}
                    {/*</div>*/}
                    <div className={content + "pt-2 text-lg"}>
                    <span className='poppins-bold '>
                        Purdue University
                    </span>
                        <span className='float-end ps-4 text-[1rem] '>
                        2022 - 2026
                    </span>
                        <br/>
                        <div className='italic lg:max-w-[80%] text-sm'>
                            B.S. in Computer Science, Minor in Business Economics
                        </div>
                        <div className='pt-4'>
                            <ul className=' list-disc px-4 text-sm'>
                                <li>Concentration in Software Engineering/Machine Intelligence</li>
                                <li>Dean's List - 4 Semesters</li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/*SKILLS*/}
                <div>
                    {/*<div className={subheading}>*/}
                    {/*    Skills*/}
                    {/*</div>*/}
                    <div className=' pt-4 text-lg poppins-bold'>
                        Languages
                    </div>
                    <div className='flex gap-2 py-2 text-sm flex-wrap'>
                        {languages.map((item, index) => (
                            <div className={tech}>{item}</div>
                        ))}
                    </div>
                    <div className='pt-2 text-lg poppins-bold'>
                        Frameworks/Libraries/Technologies
                    </div>
                    <div className='flex gap-2 py-2 text-sm flex-wrap'>
                        {frameworks.map((item, index) => (
                            <div className={tech}>{item}</div>
                        ))}
                    </div>
                </div>
                {/*AWARDS*/}
                {/*<div>*/}
                {/*    <div className={subheading}>*/}
                {/*        Awards*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            {/*TEXT DROP*/}
            <div className=' md:w-1/2 p-6'>
                <p className={pStyle + " pt-0"}>
                    I'm currently a 3rd year student at Purdue University. My interests in the field include Artificial
                    Intelligence, Cloud Computing, Distributed Systems, Data Engineering, SWE, ect.
                </p>
                <p className={pStyle}>
                    I began programming in High School with <span className={boldText}>Ease Attendance</span> and
                    <span className={boldText}> Rift</span>, both widely-adopted web/mobile apps designed
                    to streamline inefficiencies in the education system.
                </p>
                <p className={pStyle}>
                    Joining two research teams at the <span className={boldText}>University of Chicago</span> and
                    <span className={boldText}> Purdue University</span> related to Data Engineering
                    and Data Science gave me a insight into Academia.
                </p>
                <p className={pStyle}>
                    Currently, I'm looking to build work experience. After working at <span className={boldText}>Somos
                    Inc.</span> and <span className={boldText}> Cisco Meraki </span>, I'm preparing for my next
                    internship at <span className={boldText}>Meta</span>.
                </p>
            </div>
        </div>
    );
}

export default About;